

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/homepage_images/Background.jpg");
  
  background-repeat: no-repeat;
  background-size: cover ;   
}

code {
  font-family: 'Roboto', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar */
.navigation {
  background-color: none;
  position: fixed;
  top:0px;

}
.send-message{
  position: relative;
  right:25px;
}


.form{
  position:relative;
  left:30px;
}

/* Homepage */
.landing-text {
  color: #393e46;
}

.get-started {
  color: #eeeeee;
  background-color: #222831;
}
.get-started:hover {
  color: #00adb5;
}

.about-me {
  position: relative;
  right:50px;
  border-radius: 2%;
  width: 380px;
}

/* Registration Page */
.sign-up {
  background-color: #00adb5;
}

/* All badges */
.badge {
  background-color: #222831;
}

/* Settings */
.update-popup {
  background-color: #eeeeee;
}

tbody {
  background-color: #00adb5;
}

thead {
  background-color: #393e46;
}

/* Image dragging */
img {
  pointer-events: none;
}

/* About Page */
.about-button {
  background-color: #00adb5;
}
.about-button:hover {
  background-color: #222831;
  color: #eeeeee;
}

.about-modal {
  background-color: #eeeeee;
}

.contactUsTextArea {
  resize: none;
}
.View{
  text-decoration: none;
  font-weight: 700;
  color:#4b586d;
}
.location{
  margin-right:100px;
}
.lottie{
  width: 25%;
}
.lottie-container{
  width: 100%;
}