.card{
width: 49%; 
margin:5px;
}
.about-me{
    margin:auto;
   }

.skill-container{
    background-color: white;
    border-radius:5px ;
    width: 25%; 
    margin:5px;
    border: 1px solid rgb(158, 157, 157);
}
@media screen and (max-width: 1200px) {
    .card{
        width: 100%; 
        margin:5px;
        }
      
  }
  @media screen and (max-width: 775px) {
    .skill-container{
        background-color: white;
        border-radius:5px ;
        width: 40%; 
        margin:5px;
        border: 1px solid rgb(158, 157, 157);
    }
    .about-me{
     position: relative;
     right: 130px;
    }
      
  }

  @media screen and (max-width: 529px) {
    .skill-container{
        background-color: white;
        border-radius:5px ;
        width: 60%; 
        margin:5px;
        border: 1px solid rgb(158, 157, 157);
    }
    .about-me{
        position: relative;
        right: 0px;
        width: 190px;
       }
      
  }
  @media screen and (max-width: 431px) {
  
    
   input[type = text],input[type=email],textarea[type=text]{
    width: 330px;
   }
   .fw-normal{
    font-size: 17px;
   }
   .paragraph{
    font-size: 12px ;
   }
      
  }
  @media screen and (max-width: 300px) {
    .skill-container{
        background-color: white;
        border-radius:5px ;
        width: 90%; 
        margin:5px 5px 5px 30px;
        border: 1px solid rgb(158, 157, 157);
    }
    .about-me{
        position: relative;
        right: 15px;
        width: 160px;
       }
   input[type = text],input[type=email],textarea[type=text]{
    width: 200px;
   }
   .fw-normal{
    font-size: 17px;
   }
   .paragraph{
    font-size: 12px ;
   }
      
  }